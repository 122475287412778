import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="paladin"></a>PALADIN</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG43_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Lawful good.</p>
    <p><b>Hit Die</b>: d10.</p>
    <h6>Class Skills</h6>
    <p className="initial">The paladin&#8217;s class skills (and the key
ability for each skill) are <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
(Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle
Animal</a> (Cha), <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a> (Wis), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(nobility and royalty /
religion) (Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
(Dex), and <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> (Wis).</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <p><a id="table-the-paladin"></a><b>Table: The Paladin</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "40%"
          }}>Special</th>
          <th colSpan="4" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "6%"
          }}>1st</th>
          <th style={{
            "width": "6%"
          }}>2nd</th>
          <th style={{
            "width": "6%"
          }}>3rd</th>
          <th style={{
            "width": "6%"
          }}>4th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
          <td>Aura of good, detect evil, smite evil 1/day</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
          <td>Divine grace, lay on hands</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>Aura of courage, divine health</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Turn undead</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Smite evil 2/day, special mount</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6/+1</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Remove disease 1/week</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>&nbsp;</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8/+3</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>&nbsp;</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>Remove disease 2/week</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+10/+5</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>Smite evil 3/day</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+11/+6/+1</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+12/+7/+2</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>Remove disease 3/week</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+13/+8/+3</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+14/+9/+4</td>
          <td>+9</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+15/+10/+5</td>
          <td>+9</td>
          <td>+5</td>
          <td>+5</td>
          <td>Remove disease 4/week, smite evil 4/day</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+16/+11/+6/+1</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+17/+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>2</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+18/+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>Remove disease 5/week</td>
          <td>3</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+19/+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+20/+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">Smite evil 5/day</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="paladin-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
paladin.</p>
    <p><b>Weapon and Armor Proficiency</b>: Paladins are proficient
with all simple and martial weapons, with all types of armor (heavy,
medium, and light), and with shields (except tower shields).</p>
    <p><b>Aura of Good (Ex)</b>: The power of a paladin&#8217;s aura of
good (see the detect good spell) is equal to her paladin level.</p>
    <p><b>Detect Evil (Sp)</b>: At will, a paladin can use detect
evil, as the spell.</p>
    <p><a id="paladin-smite-evil"></a><b>Smite Evil (Su)</b>: Once per day, a paladin may attempt to
smite evil with one normal melee attack. She adds her Charisma bonus
(if any) to her attack roll and deals 1 extra point of damage per
paladin level. If the paladin accidentally smites a creature that is
not evil, the smite has no effect, but the ability is still used up for
that day.</p>
    <p>At 5th level, and at every five levels thereafter, the paladin
may smite evil one additional time per day, as indicated on Table: The
Paladin, to a maximum of five times per day at 20th level.</p>
    <p><a id="paladin-divine-grace"></a><b>Divine Grace (Su)</b>: At 2nd level, a paladin gains a
bonus equal to her Charisma bonus (if any) on all saving throws.</p>
    <p><a id="paladin-lay-on-hands"></a><b>Lay on Hands (Su)</b>: Beginning at 2nd level, a paladin
with a Charisma score of 12 or higher can heal wounds (her own or those
of others) by touch. Each day she can heal a total number of hit points
of damage equal to her paladin level x her Charisma bonus. A paladin
may choose to divide her healing among multiple recipients, and she
doesn&#8217;t have to use it all at once. Using lay on hands is a standard
action.</p>
    <p>Alternatively, a paladin can use any or all of this healing
power to deal damage to undead creatures. Using lay on hands in this
way requires a successful melee touch attack and doesn&#8217;t provoke an
attack of opportunity. The paladin decides how many of her daily
allotment of points to use as damage after successfully touching an
undead creature.</p>
    <p><a id="paladin-aura-of-courage"></a><b>Aura of Courage (Su)</b>: Beginning at 3rd level, a paladin
is immune to fear (magical or otherwise). Each ally within 10 feet of
her gains a +4 morale bonus on saving throws against fear effects. This
ability functions while the paladin is conscious, but not if she is <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a> or <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>.</p>
    <p><a id="paladin-divine-health"></a><b>Divine Health (Ex)</b>: At 3rd level, a paladin gains
immunity to all diseases, including supernatural and magical diseases.</p>
    <p><a id="paladin-turn-undead"></a><b>Turn Undead (Su)</b>: When a paladin reaches 4th level, she
gains the supernatural ability to <a href="specialAttacks.html#turn" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn undead</a>. She may use this
ability
a number of times per day equal to 3 + her Charisma modifier. She turns
undead as a cleric of three levels lower would.</p>
    <p><b>Spells</b>: Beginning at 4th level, a paladin gains the
ability to cast a small number of divine spells, which are drawn from
the <a style={{
        "color": "#579eb6"
      }} href="paladinSpells.html">paladin spell list</a>. A paladin must choose and prepare her spells in
advance.</p>
    <p>To prepare or cast a spell, a paladin must have a Wisdom score
equal to at least 10 + the spell level. The Difficulty Class for a
saving throw against a paladin&#8217;s spell is 10 + the spell level + the
paladin&#8217;s Wisdom modifier.</p>
    <p>Like other spellcasters, a paladin can cast only a certain
number of spells of each spell level per day. Her base daily spell
allotment is given on Table: The Paladin. In addition, she receives
bonus spells per day if she has a high Wisdom score. When Table: The
Paladin indicates that the paladin gets 0 spells per day of a given
spell level, she gains only the bonus spells she would be entitled to
based on her Wisdom score for that spell level The paladin does not
have access to any domain spells or granted powers, as a cleric does.</p>
    <p>A paladin prepares and casts spells the way a cleric does,
though she cannot lose a prepared spell to spontaneously cast a cure
spell in its place. A paladin may prepare and cast any spell on the
paladin spell list, provided that she can cast spells of that level,
but she must choose which spells to prepare during her daily meditation.</p>
    <p>Through 3rd level, a paladin has no caster level. At 4th level
and higher, her caster level is one-half her paladin level.</p>
    <p><a id="paladin-special-mount"></a><b>Special Mount (Sp)</b>: Upon reaching 5th level, a paladin
gains the service of an unusually intelligent, strong, and loyal steed
to serve her in her crusade against evil (see below). This mount is
usually a heavy warhorse (for a Medium paladin) or a warpony (for a
Small paladin).</p>
    <p>Once per day, as a full-round action, a paladin may magically
call her mount from the celestial realms in which it resides. This
ability is the equivalent of a spell of a level equal to one-third the
paladin&#8217;s class level. The mount
immediately appears adjacent to the paladin and remains for 2 hours per
paladin level; it may be dismissed at any time as a free action. The
mount is the same creature each time it is summoned, though the paladin
may release a particular mount from service.</p>
    <p>Each time the mount is called, it appears in full health,
regardless of any damage it may have taken previously. The mount also
appears wearing or carrying any gear it had when it was last dismissed.
Calling a mount is a conjuration (calling) effect.</p>
    <p>Should the paladin&#8217;s mount die, it immediately disappears,
leaving behind any equipment it was carrying. The paladin may not
summon another mount for thirty days or until she gains a paladin
level, whichever comes first, even if the mount is somehow returned
from the dead. During this thirty-day period, the paladin takes a &#8211;1
penalty on attack and weapon damage rolls.</p>
    <p><a id="paladin-remove-disease"></a><b>Remove Disease (Sp)</b>: At 6th level, a paladin can
produce a remove disease effect, as the spell, once per week. She can
use this ability one additional time per week for every three levels
after 6th (twice per week at 9th, three times at 12th, and so forth).</p>
    <p><a id="paladin-code-of-conudct"></a><b>Code of Conduct</b>: A paladin must be of lawful good
alignment and loses all class abilities if she ever willingly commits
an evil act. Additionally, a paladin&#8217;s code requires that she respect
legitimate authority, act with honor (not lying, not cheating, not
using poison, and so forth), help those in need (provided they do not
use the help for evil or chaotic ends), and punish those who harm or
threaten innocents.</p>
    <p><a id="paladin-associates"></a><b>Associates</b>: While she may adventure with characters of
any good or neutral alignment, a paladin will never knowingly associate
with evil characters, nor will she continue an association with someone
who consistently offends her moral code. A paladin may accept only
henchmen, followers, or cohorts who are lawful good.</p>
    <h6><a id="ex-paladins"></a>Ex-Paladins</h6>
    <p className="initial">A paladin who ceases to be lawful good, who
willfully commits an evil act, or who grossly violates the code of
conduct loses all paladin spells and abilities (including the service
of the paladin&#8217;s mount, but not weapon, armor, and shield
proficiencies). She may not progress any farther in levels as a
paladin. She regains her abilities and advancement potential if she
atones for her violations (see the atonement spell description), as
appropriate.</p>
    <p>Like a member of any other class, a paladin may be a
multiclass character, but multiclass paladins face a special
restriction. A paladin who gains a level in any class other than
paladin may never again raise her paladin level, though she retains all
her paladin abilities.</p>
    <h5><a id="the-paladings-mount"></a>THE PALADIN&#8217;S MOUNT</h5>
    <p className="initial">The paladin&#8217;s mount is superior to a normal
mount of its kind and has special powers, as described below. The
standard mount for a Medium paladin is a heavy warhorse, and the
standard mount for a Small paladin is a warpony. Another kind of mount,
such as a riding dog (for a halfling paladin) or a Large shark (for a
paladin in an aquatic campaign) may be allowed as well. A paladin&#8217;s
mount is treated as a magical beast, not an animal, for the purpose of
all effects that depend on its type (though it retains an animal&#8217;s HD,
base attack bonus, saves, skill points, and feats).</p>
    <table style={{
      "marginTop": "0.5em",
      "width": "75%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th><a id="table-the-paladins-mount"></a>Paladin Level</th>
          <th>Bonus HD</th>
          <th>Natural Armor Adj.</th>
          <th>Str Adj.</th>
          <th>Int</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>5th&#8211;7th</td>
          <td>+2</td>
          <td>+4</td>
          <td>+1</td>
          <td>6</td>
          <td>Empathic link, improved evasion, share spells, share
saving throws</td>
        </tr>
        <tr>
          <td>8th&#8211;10th</td>
          <td>+4</td>
          <td>+6</td>
          <td>+2</td>
          <td>7</td>
          <td>Improved speed</td>
        </tr>
        <tr className="odd-row">
          <td>11th&#8211;14th</td>
          <td>+6</td>
          <td>+8</td>
          <td>+3</td>
          <td>8</td>
          <td>Command creatures of its kind</td>
        </tr>
        <tr>
          <td className="last-row">15th&#8211;20th</td>
          <td className="last-row">+8</td>
          <td className="last-row">+10</td>
          <td className="last-row">+4</td>
          <td className="last-row">9</td>
          <td className="last-row">Spell resistance</td>
        </tr>
      </tbody>
    </table>
    <p><a id="paladins-mount-basics"></a><b>Paladin&#8217;s Mount Basics</b>: Use the base statistics for a
creature of the mount&#8217;s kind, but make changes to take into account the
attributes and characteristics summarized on the table and described
below.</p>
    <p><i>Bonus HD</i>: Extra eight-sided (d8) Hit Dice, each of
which gains a Constitution modifier, as normal. Extra Hit Dice improve
the mount&#8217;s base attack and base save bonuses. A special mount&#8217;s base
attack bonus is equal to that of a cleric of a level equal to the
mount&#8217;s HD. A mount has good Fortitude and Reflex saves (treat it as a
character whose level equals the animal&#8217;s HD). The mount gains
additional skill points or feats for bonus HD as normal for advancing a
monster&#8217;s Hit Dice.</p>
    <p><i>Natural Armor Adj.</i>: The number on the table is an
improvement to the mount&#8217;s existing natural armor bonus.</p>
    <p><i>Str Adj.</i>: Add this figure to the mount&#8217;s Strength score.</p>
    <p><i>Int</i>: The mount&#8217;s Intelligence score.</p>
    <p><a id="paladins-mount-empathic-link"></a><i>Empathic Link (Su)</i>: The paladin has an empathic link
with her mount out to a distance of up to 1 mile. The paladin cannot
see through the mount&#8217;s eyes, but they can communicate empathically.
Note that even intelligent mounts see the world differently from
humans, so misunderstandings are always possible.</p>
    <p>Because of this empathic link, the paladin has the same
connection to an item or place that her mount does, just as with a
master and his familiar (see Familiars).</p>
    <p><a id="paladins-mount-imporved-evasion"></a><i>Improved Evasion (Ex)</i>: When subjected to an attack that
normally allows a Reflex saving throw for half damage, a mount takes no
damage if it makes a successful saving throw and half damage if the
saving throw fails.</p>
    <p><a id="paladins-mount-share-spells"></a><i>Share Spells</i>: At the paladin&#8217;s option, she may have any
spell (but not any spell-like ability) she casts on herself also affect
her mount. The mount must be within 5 feet at the time of casting to
receive the benefit. If the spell or effect has a duration other than
instantaneous, it stops affecting the mount if it moves farther than 5
feet away and will not affect the mount again even if it returns to the
paladin before the duration expires. Additionally, the paladin may cast
a spell with a target of &#8220;You&#8221; on her mount (as a touch range spell)
instead of on herself. A paladin and her mount can share spells even if
the spells normally do not affect creatures of the mount&#8217;s type
(magical beast).</p>
    <p><a id="paladins-mount-share-saving-throws"></a><i>Share Saving Throws</i>: For each of its saving throws, the
mount uses its own base save bonus or the paladin&#8217;s, whichever is
higher. The mount applies its own ability modifiers to saves, and it
doesn&#8217;t share any other bonuses on saves that the master might have.</p>
    <p><a id="paladins-mount-improved-speed"></a><i>Improved Speed (Ex)</i>: The mount&#8217;s speed increases by 10
feet.</p>
    <p><a id="paladins-mount-command"></a><i>Command (Sp)</i>: Once per day per two paladin levels of
its master, a mount can use this ability to command other any normal
animal of approximately the same kind as itself (for warhorses and
warponies, this category includes donkeys, mules, and ponies), as long
as the target creature has fewer Hit Dice than the mount. This ability
functions like the command spell, but the mount must make a DC 21
Concentration check to succeed if it&#8217;s being ridden at the time. If the
check fails, the ability does not work that time, but it still counts
against the mount&#8217;s daily uses. Each target may attempt a Will save (DC
10 + 1/2 paladin&#8217;s level + paladin&#8217;s Cha modifier) to negate the effect.</p>
    <p><i>Spell Resistance (Ex)</i>: A mount&#8217;s spell resistance
equals its master&#8217;s paladin level + 5. To affect the mount with a
spell, a spellcaster must get a result on a caster level check (1d20 +
caster level) that equals or exceeds the mount&#8217;s spell resistance.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      